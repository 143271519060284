@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.after-effect {
  position: relative;
  display: inline-block;
  --tw-gradient-from: #003DC2;
  --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
  --tw-gradient-to: #6300AA;
  font-size: 2.5rem;
  font-weight: 600;
}

.dark .after-effect {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.after\:left-52:after {
  content: var(--tw-content);
  left: 13rem;
}

.after-effect:after {
  content: var(--tw-content);
  width: 12rem;
}

.after-effect:after {
  position: absolute;
  top: 50%;
  height: .125rem;
  --tw-translate-y: -50%;
  border-radius: .375rem;
  background-image: linear-gradient(to right,var(--tw-gradient-stops));
  --tw-content: "";
  content: var(--tw-content);
}
